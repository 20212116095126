import { Component } from '@angular/core';


@Component({
    templateUrl: './contact-me.component.html',
    styleUrls: ['./contact-me.component.scss'],
    selector: 'contact-me'
})

export class ContactMeComponent {

  constructor() {
    //
  }
}
