import { Component } from '@angular/core';


@Component({
    templateUrl: './about-me.component.html',
    styleUrls: ['./about-me.component.scss'],
    selector: 'about-me'
})

export class AboutMeComponent {

  constructor() {
    //
  }
}
