export const environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyAJ-Z3o6Ju1JoOQ6hv0GjWOrXINueymRCU',
    authDomain: 'my-woodburnings.firebaseapp.com',
    databaseURL: 'https://my-woodburnings.firebaseio.com',
    projectId: 'my-woodburnings',
    storageBucket: 'my-woodburnings.appspot.com',
    messagingSenderId: '619821469480'
  }
};
