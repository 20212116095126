import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BlogDetails } from '../interfaces';
import { BlogService } from '../admin/admin-blogs/blog.service';

@Component({
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
    selector: 'blog'
})

export class BlogComponent implements OnInit {

    public allBlogs$: Observable<BlogDetails[]>;
    public hasPublishedBlogs: boolean = false;

    constructor(private blogService: BlogService) {
    //
    }

    ngOnInit(): void {
        this.allBlogs$ = this.blogService.list();
        this.allBlogs$.subscribe((blogs ) => {
            for (let i = 0; i < blogs.length; i++) {
                if (blogs[i].published) {
                    this.hasPublishedBlogs = true;
                }
            }
        });
    }
}
