import { Component } from '@angular/core';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

/** The base component that bootstraps angular */
export class AppComponent {

  constructor() {
    //
  }
}
