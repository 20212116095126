import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    templateUrl: './admin-blogs.component.html',
    styleUrls: ['./admin-blogs.component.scss'],
    selector: 'admin-blog'
})

export class AdminBlogsComponent {

  constructor(private router: Router) {
  }

  navigateToCreateBlogPage(): void {
    this.router.navigate(['/admin-blogs/write-blog']);
  }
}
